import React from 'react'
import PropTypes from 'prop-types'
import Layout from '../components/layout/Layout'
import ImageSlider from "../components/image/ImageSlider";
import { useIsMobile } from "../hooks/useIsMobile";
import CeramiqueFormules from "../components/ceramique/CeramiqueFormules";
import GiftCardBanner from "../components/gift-card/GiftCardBanner";
import ReservationClosedBanner from "../components/ReservationClosedBanner";
import AddOrEditReservationButton from "../components/calendrier/AddOrEditReservationButton";

const imageStyle = {borderRadius: '0px', margin: 'auto', height : "200px",  objectFit: "contain", justifyContent: "center", alignItems: "center", alignSelf: "center"   }

const dataCeramiqueSemaine = [
  {
    images : ["/img/v2/Fpeinturesurceramiquegalerie-min.JPG", "/img/v2/Fpeinturesurceramiquegalerie2-min.JPG", "/img/v2/Fpeinturesurceramiquegalerie3-min.JPG"],
    title : "Créneau classique",
    text1 : "À partir de 25€",
    text2 : "2H de peinture sur céramique, prix en fonction de la taille de la pièce choisie",
    text3 : "Du mardi au vendredi avec ou sans réservation.",
    handle: "creneau-classique-ceramique"
  },
  {
    images : ["/img/v2/Gexpresspeinture-min.jpg"],
    title : "Créneau express",
    text1 : "À partir de 20€",
    text2 : "1H de peinture sur céramique sur une pièce XS",
    text3 : "Du mardi au vendredi à 16h30 avec ou sans réservation.",
    handle: "creneau-express-ceramique"
  },
]

const dataCeramiqueSamedi = [
  {
    images : ["/img/v2/Hgouterceram-min.jpg", "/img/v2/Hgouterceramiquegalerie1-min.JPG", "/img/v2/Hgouterceramgalerie2-min.jpg" ],
    title : "Petit-dèj / gouter céramique",
    text1 : "À partir de 37€",
    text2 : "2H de peinture sur céramique + 1 patisserie + 1 boisson",
    text3 : "Samedi à 10H ou 15H, réservation vivement conseillée",
    handle: "dej-gouter-ceramique"
  },
  {
    images : ["/img/v2/Ibrunchceramgalerie2-min.JPG", "/img/v2/Ibrunchceramgalerie3-min.JPG", "/img/v2/Ibrunchceramgalerie4-min.JPG"],
    title : "Brunch céramique",
    text1 : "À partir de 52€",
    text2 : "2H de peinture sur céramique + notre formule brunch",
    text3 : "Tout les samedis, réservation vivement conseillée",
    handle: "brunch-ceramique-1"
  },
]

const dataCeramiqueApero = [
  {
    images : ["/img/v2/Japeropeinturegalerie1-min.jpg", "/img/v2/Japeropeinturegalerie2-min.jpg", "/img/v2/Japeropeinturegalerie3-min.jpg"],
    title : "Apéro peinture sur céramique",
    text1 : "À partir de 45€",
    text2 : "3H de peinture sur céramique, avec une assiette salée végé + une boisson",
    text3 : "Mercredi à 18h30",
    handle: "apero-ceramique"
  }
]

const formats = [
  {
    name: "XS",
    price: 25,
    images : ["../img/v2/format/EformatXS-min.jpeg"]
  },
  {
    name: "S",
    price: 30,
    images : ["/img/v2/format/EformatS-min.jpeg"]
  },
  {
    name: "M",
    price: 35,
    images : ["/img/v2/format/EformatM-min.jpeg"]
  },
  {
    name: "L",
    price: 40,
    images : ["/img/v2/format/EformatL-min.jpeg"]
  },
  {
    name: "XL",
    price: 45,
    images : ["/img/v2/format/EformatXL-min.jpeg"]
  },
  {
    name: "XXL",
    price: 50,
    images : ["/img/v2/format/EformatXXL-min.jpeg"]
  }
]

export const CafeCeramiqueTemplate = () => {

  const { isMobile } = useIsMobile();

  const imageInfo1 = {
    "alt": "Des créations céramique",
    "image": "/img/v2/Dcafeceramique-min.JPG",
    "maxWidth": "350"
  }

  return (
    <div className="content">
      <AddOrEditReservationButton isEdit={false} dayOfWeek={"Lundi"} reservation={null}/>
      <section className="section section--gradient">
        <div className="container">
          <div className="columns">
            <div className="column is-12">
              <div className="columns has-text-justified">
                <div className="column is-6">
                  <h3 className="title is-4">Révélez votre potentiel créatif !</h3>

                  <p>Venez vous essayer à la peinture sur céramique en choisissant la pièce de faïence blanche de votre choix à décorer.</p>

                  <p>Vous trouverez sur place tout le matériel nécessaire ainsi que des planches d’inspiration.</p>

                  <p>Cette activité est ouverte à tous les âges et niveaux! Notre équipe est là pour vous expliquer les différents outils et techniques de décor.</p>

                  <p>Comment cela fonctionne :</p>
                  <ul>
                    <li>Vous sélectionnez un objet et le décorez</li>
                    <li>Nous nous chargeons de l’émaillage et de la cuisson qui rendront l’usage de votre objet compatible avec l’alimentaire.</li>
                    <li>Vous récupérez votre création quelques semaines plus tard.</li>
                  </ul>
                  <p className="is-bold" style={{fontWeight : "bold"}}>Le prix de cette activité varie en fonction de la taille de la pièce choisie.</p>

                  <GiftCardBanner/>
                </div>

                <div className="column has-text-centered is-6" style={{margin:'auto'}}>
                  <img src={imageInfo1.image} alt={imageInfo1.alt} style={{alignSelf:"center", verticalAlign:'middle', maxWidth: 350}} />
                </div>
              </div>
            </div>
          </div>

          <div  className="content is-offset-1" style={{marginTop: '2rem'}}>
            <h3 className="title is-4">6 formats, 6 prix :</h3>
            <div className="columns is-mobile is-flex is-multiline is-flex-mobile">
              {formats.map((format) => {
                let images = [];
                format.images.map((img) => {
                  images.push({image : img, description : "Des formats utilisable chez forma : pose-savon, tasse, assiette, pot de fleurs ..."})
                })
                // Bulma responsive media queries sucks, so I'm using a if :
                if(isMobile) {
                  return (
                    <div className="row" style={{ flexDirection: "column", justifyContent: "center", alignItems: "center", display: "flex", width : "50%" }}>
                      <ImageSlider slides={images} imageStyle={imageStyle} autoPlay={false}/>
                      <h2 style={{ margin: "0.5rem", marginBottom : "0.1rem", color : "#8f4431", fontSize : "1.4rem", fontWeight : "bold" }}>{format.name}</h2>
                      <h2 style={{ margin: "0.5rem",  fontSize : "1.1rem" }}>{format.price} €</h2>
                    </div>
                  )
                } else {
                  return (
                    <div className="column" style={{ flexDirection: "column", justifyContent: "center", alignItems: "center", display: "flex" }}>
                      <ImageSlider slides={images} imageStyle={imageStyle} autoPlay={false}/>
                      <span className="" style={{ margin: "0.5rem", marginBottom : "0.1rem", color : "#8f4431", fontSize : "1.4rem", fontWeight : "bold"}}>{format.name}</span>
                      <h2 style={{ margin: "0.5em", fontSize : "1.1rem" }}>{format.price} €</h2>
                    </div>
                  )
                }
              })}
            </div>
          </div>

          <div className="content is-offset-1" style={{marginTop: '2rem'}}>
            <p></p>
            <div>
              <h3 className="title is-4">Créneaux en semaine</h3>
            </div>
            <CeramiqueFormules formules={dataCeramiqueSemaine}/>
          </div>

          <div className="content is-offset-1" style={{marginTop: '2rem'}}>
            <p></p>
            <div>
              <h3 className="title is-4">Nos formules du samedi</h3>
            </div>
            <CeramiqueFormules formules={dataCeramiqueSamedi}/>
          </div>

          <div className="content is-offset-1" style={{marginTop: '2rem'}}>
            <p></p>
            <div>
              <h3 className="title is-4"> L'apéro céramique</h3>
            </div>
            <CeramiqueFormules formules={dataCeramiqueApero}/>
          </div>

        </div>
      </section>
    </div>
  )}

CafeCeramiqueTemplate.propTypes = {
  imageAcceuil: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
}

const CafeCeramique = () => {
  return (
    <Layout>
      <CafeCeramiqueTemplate
      />
    </Layout>
  )
}

export default CafeCeramique
