import React from 'react'
import PropTypes from 'prop-types'
import { Link } from "gatsby";
import ImageSlider from "../image/ImageSlider";
import { useIsMobile } from "../../hooks/useIsMobile";


const getSliderObjectFromListOfImages = (images) => {
  let sliderObject = [];
  images.map((img) => {
    sliderObject.push({image : img, description : "Des réalisations céramiques et potteries a l'atelier forma"})
  })
  return sliderObject
}



const CeramiqueFormules = ({formules}) => {

  const {isMobile} = useIsMobile()

  const imageStyle = {alignSelf:"center", verticalAlign:'middle', minWidth : "120px"}

  return (
  <div className="columns is-multiline">

    {formules.map((item, index) => (

      <div className="is-parent column is-6" key={item.title} style={{ alignItems : "stretch", display : "flex"}}>
        <article
          className={"tile is-child atelier-list-item box"}
          style={{  borderRadius : 0, height : "100%", display : "flex", width : "100%"}}
        >
          <div className="columns" style={{width : "100%"}}>
            <div className="column is-5 has-text-centered">
              <ImageSlider slides={getSliderObjectFromListOfImages(item.images)} imageStyle={imageStyle} autoPlay={false}/>
            </div>
            <div className="column is-7 is-flex-direction-column is-flex" style={{justifyContent : "space-between" }}>
              <div style={{marginTop: "20px"}}>
                <div>
                  <h3>{item.title}</h3>
                </div>
                <div style={{marginBottom: 20, color : "gray", fontSize : "1rem"}}>
                  <p>{ item.text1}</p>
                </div>
                <div style={{marginBottom: 20}}>
                  <span>{ item.text2}</span>
                </div>
                <div>
                  <span>{ item.text3}</span>
                </div>
              </div>
              <div style={{ display: "flex", alignItems: "center", justifyContent: "center"}}>
                {
                  item.handle === "apero-ceramique" ?
                    <Link className="button" to={"/ateliers"}
                          state={{ title: "Apéro peinture sur céramique"}}
                          style={{ marginTop: "20px", width : "100px"}}>
                      Réserver
                    </Link>
                    :
                    <Link className="button" to={"/ateliers/" + item.handle} style={{ marginTop: "20px", width : "100px"}}>
                      Réserver
                    </Link>
                }
              </div>
            </div>
          </div>
        </article>
      </div>

    ))}
  </div>
)}

CeramiqueFormules.propTypes = {
  formules: PropTypes.arrayOf(
    PropTypes.shape({
      images: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object, PropTypes.string])),
      title: PropTypes.string,
      text1: PropTypes.string,
      text3: PropTypes.string,
      handle: PropTypes.string,
    })
  ),
}

export default CeramiqueFormules
